import { Tracker } from '~/plugins/analytics/tracker'
import { GoogleTrackingProvider, OSPPageViewEventMeta, PageViewEventMeta } from '~/plugins/analytics/types'
import { PageViewEvent } from '~/plugins/analytics/events/page-view.event'
import { OSPPageViewEvent } from '~/plugins/analytics/events/osp-page-view.event'
import { ECommerceEvent, ECommerceEventMeta } from '~/plugins/analytics/events/ecommerce.event'

export class GATracker extends Tracker<GoogleTrackingProvider> {
  constructor (
    protected provider: GoogleTrackingProvider,
    protected readonly debug: boolean = true,
  ) {
    super(provider, debug)
  }

  public createPageViewEvent (meta: PageViewEventMeta) {
    return new PageViewEvent(meta, this.options)
  }

  public createOSPPageViewEvent (meta: OSPPageViewEventMeta) {
    return new OSPPageViewEvent({
      contentGroup: `OSP ${meta.stepName}`,
      ...meta,
    }, this.options)
  }

  public createECommerceEvent (meta: ECommerceEventMeta) {
    return new ECommerceEvent(meta, this.options)
  }
}
