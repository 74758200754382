
import { Component, Prop, Vue } from 'nuxt-property-decorator'

@Component
export default class RatingStars extends Vue {
  // Not required as the value can be null or undefined in some cases
  // Most of the time it will be a number
  @Prop({ required: false, default: 0, type: Number })
  private readonly score!: number

  @Prop({ required: false, default: 'sm', type: String })
  private readonly size!: string

  get roundedScore () {
    return Math.ceil(this.score)
  }
}
