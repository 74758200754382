var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{class:[
    'app-provider-rating',
    `app-provider-rating--theme_${_vm.theme}`,
    {'app-provider-rating--column': _vm.direction === 'column'}
  ]},[_c('div',{staticClass:"app-provider-rating__logo",class:{'app-provider-rating__logo--with-background': _vm.withBackground},attrs:{"data-e2e":"providerLogo","data-pk":_vm.providerId}},[_c('img',{attrs:{"src":_vm.logoUrl,"alt":_vm.providerName}})]),_vm._v(" "),_c('div',{staticClass:"app-provider-rating__stars-wrapper",attrs:{"data-e2e":"providerRating"}},[_c('div',{staticClass:"app-provider-rating__stars"},_vm._l((5),function(index){return _c('IconStar',{key:index,staticClass:"app-provider-rating__icon-star",class:{
          'app-provider-rating__icon-star--filled': index <= _vm.filledStarCount,
        }})}),1),_vm._v(" "),_c('div',{staticClass:"app-provider-rating__count"},[_vm._v("\n      ("+_vm._s(_vm.innerReviews)+")\n    ")])])])
}
var staticRenderFns = []

export { render, staticRenderFns }