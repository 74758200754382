export const providerRatingMap: Record<string, { rating: number, reviews: number }> = {
  swisscom: {
    rating: 5,
    reviews: 49,
  },
  sunrise: {
    rating: 4,
    reviews: 63,
  },
  salt: {
    rating: 4,
    reviews: 72,
  },
  wingo: {
    rating: 5,
    reviews: 76,
  },
  yallo: {
    rating: 4,
    reviews: 49,
  },
  talktalk: {
    rating: 4,
    reviews: 17,
  },
  'm-budget': {
    rating: 4,
    reviews: 6,
  },
  quickline: {
    rating: 5,
    reviews: 3,
  },

}
