import {
  PageViewEventMeta,
  PageViewEventPayload,
  TrackEventOptions,
  WithEventProperty,
} from '~/plugins/analytics/types'
import { GATrackEvent } from '~/plugins/analytics/events/ga.event'

export class PageViewEvent extends GATrackEvent<PageViewEventPayload> {
  constructor (private meta: PageViewEventMeta, protected options: TrackEventOptions) {
    super(options.provider, options.debug)
  }

  get previousUrl () {
    if (this.meta.fromPath) {
      return location.origin + this.meta.fromPath
    }

    return ''
  }

  get payload (): WithEventProperty<PageViewEventPayload> {
    const { routeName, pageTitle, fullPath, contentGroup } = this.meta

    return {
      event: 'sendVirtualPageview',
      routeName,
      pageTitle,
      pageType: 'PageView',
      previousUrl: this.previousUrl,
      fullUrl: location.href,
      vpv: location.href.replace(location.origin, ''),
      pageUrl: fullPath,
      contentGroup,
    }
  }
}
